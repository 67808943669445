const { v4: uuidv4 } = require('uuid');

export class SigmaChat {
  static #user = null
  static #clientId = null
  static #options = {
    id: 'sigmachatid',
    title: 'Chat Sigma',
    headline: 'Digite seu Número e Nome',
    logo: null,
    icon: null,
    userIcon: null,
    dateFormat: 'HH:mm',
    drawFAB: true,
    autoMessage: null,
    color: '#323E48',
    links: [],
    fields: [],
    hideField: [],
    phone: {},
    links: [],
    assistent: null,
    timerPopup: 5000,
    titlePopUp: '',
    messagePopUp: '',
    lang: {
      name: 'Name',
      namePH: 'Type your name',
      email: 'Email',
      emailPH: 'Type your email',
      phone: 'Phone',
      phonePH: 'Type your phone',
      submit: 'Submit',
      assistance: 'Assistance',
      message: 'Message',
      messagePH: 'Type your message here...',
      download: 'Download',
      video_not_support: 'Your browser does not support the video tag.'
    },
    footer:''
  }
  constructor(options) {
    SigmaChat.#options = { ...SigmaChat.#options, ...options };
    SigmaChat.#options.phone = {
      code: null,
      pattern: "\\+\\d{7,15}",
      title: "Number format E164. E.g. +9999999999",
      maskOptions: {
        mask: '{+}0000000[00000000]'
      },
      ...SigmaChat.#options.phone
    }
    if (SigmaChat.#options.lang.url) {
      fetch(`${process.env.MIX_PUSHER_HOST}/dist/lang/${SigmaChat.#options.lang.url}.json`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            SigmaChat.#options.lang = {
              name: 'Name',
              namePH: 'Type your name',
              email: 'Email',
              emailPH: 'Type your email',
              phone: 'Phone',
              phonePH: 'Type your phone',
              submit: 'Submit',
              assistance: 'Assistance',
              message: 'Message',
              messagePH: 'Type your message here...',
              download: 'Download',
              ...SigmaChat.#options.lang
            }
          }
        })
        .then(data => {
          SigmaChat.#options.lang = { ...data, ...SigmaChat.#options.lang };
        });
    } else {
      SigmaChat.#options.lang = {
        name: 'Name',
        namePH: 'Type your name',
        email: 'Email',
        emailPH: 'Type your email',
        phone: 'Phone',
        phonePH: 'Type your phone',
        submit: 'Submit',
        assistance: 'Assistance',
        message: 'Message',
        messagePH: 'Type your message here...',
        downlaod: 'Download',
        ...SigmaChat.#options.lang
      }
    }
    if (SigmaChat.#options.drawFAB) {
      this.#drawFAB();
    }
  }


  #drawFAB() {
    if(SigmaChat.#options.assistent == true) {
      setTimeout(() => {
        const popup = document.createElement('div');
        popup.classList.add('sigma-popup');
        popup.innerHTML = `
        <div class="sigma-popup-header">
          <div class="sigma-popup-title">
            <div class='sigma-popup-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </div>
            <p class='sigma-popup-messege'>
              ${SigmaChat.#options.titlePopUp}
            </p>
          </div>
          <div>
            <button type='button' class='sigma-popup-close'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="sigma-popup-body">
          <p class='txt-help'>${SigmaChat.#options.messagePopUp}</p>
        </div>
        `;
        document.body.appendChild(popup);

        const closePopup = document.querySelector('.sigma-popup-close');
        closePopup.setAttribute('onclick', 'SigmaChat.closePopup()');

      }, SigmaChat.#options.timer);
    }

    const root = document.createElement('div');
    root.classList.add('sigma-fab');
    root.setAttribute('ontouchstart', "");
    document.body.appendChild(root);

    const main = document.createElement('button');
    main.classList.add('sigma-main');
    main.style.backgroundColor = SigmaChat.#options.color;
    if (SigmaChat.#options.links.length > 0) {
      main.addEventListener('click', function () {
        const btn = document.querySelector('.sigma-fab button.sigma-main');
        btn.classList.toggle('sigma-visible');
      })
    } else {
      main.setAttribute('onclick', 'SigmaChat.showChat()');
    }
    root.appendChild(main);

    const btnImg = document.createElement('img');
    btnImg.setAttribute('id', `sigma-fab-img`);
    btnImg.setAttribute('src', `${process.env.MIX_PUSHER_HOST}/dist/img/chat.svg`);
    main.appendChild(btnImg);

    const ul = document.createElement('ul');
    root.appendChild(ul);

    SigmaChat.#options.links.forEach((link, index) => {
      const li = document.createElement('li');
      ul.appendChild(li);
      const label = document.createElement('label');
      label.classList.add('sigma-fab-label');
      label.setAttribute('for', `sigma-fab-link-${index}`);
      label.innerHTML = link.label || '';
      li.appendChild(label);

      const a = document.createElement('a');
      a.classList.add('sigma-fab-btn');
      a.setAttribute('id', `sigma-fab-link-${index}`);
      a.setAttribute('href', link.href || '#');
      a.setAttribute('target', '_blank');
      a.style.backgroundColor = link.color || '#748F9F';
      li.appendChild(a);

      const img = document.createElement('img');
      img.setAttribute('id', `sigma-fab-link-${index}-img`);
      img.setAttribute('src', link.img);
      a.appendChild(img);
    });

    if (SigmaChat.#options.links.length > 0) {
      const li = document.createElement('li');
      ul.appendChild(li);
      const label = document.createElement('label');
      label.setAttribute('for', 'sigma-fab-link-web');
      label.innerHTML = 'Chat';
      li.appendChild(label);

      const a = document.createElement('a');
      a.setAttribute('id', 'sigma-fab-link-web');
      a.setAttribute('href', '#');
      a.setAttribute('onclick', 'SigmaChat.showChat()');
      a.style.backgroundColor = '#748F9F';
      li.appendChild(a);

      const img = document.createElement('img');
      img.setAttribute('id', `sigma-fab-link-web-img`);
      img.setAttribute('src', `${process.env.MIX_PUSHER_HOST}/dist/img/web.svg`);
      a.appendChild(img);
    }

  };

  static #drawChat() {
    const root = document.createElement('div');
    root.classList.add('sigma-container');
    document.body.appendChild(root);

    const btnClose = document.createElement('a');
    btnClose.classList.add('sigma-btn-close');
    btnClose.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="sigma-bi sigma-bi-x-lg" viewBox="0 0 16 16" style="color: black;margin-top: 1vw;">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path>
  </svg>`
    btnClose.setAttribute('href', '#');
    btnClose.setAttribute('onclick', 'SigmaChat.close()');
    root.appendChild(btnClose);

    const span = document.createElement('span');
    span.classList.add('sigma-btn-close');
    span.style.float = 'left';
    span.style.content = 'none';
    root.appendChild(span);

    const form = document.createElement('form');
    form.classList.add('sigma-form');
    form.setAttribute('id', 'sigma-chat');
    form.setAttribute('onsubmit', 'event.preventDefault();SigmaChat.initChat()');
    root.appendChild(form);

    if (SigmaChat.#options.logo && (SigmaChat.#options.logo instanceof Object)) {
      const img = document.createElement('img');
      img.classList.add('sigma-logo');
      const customClasses = (SigmaChat.#options.logo.class || "").split(" ").filter(value => value !== '');
      if (customClasses.length > 0) {
        img.classList.add(...customClasses);
      }
      Object.entries(SigmaChat.#options.logo).forEach(attr => {
        if (['class', 'id', 'style'].includes(attr[0])) {
          return;
        }
        img.setAttribute(attr[0], attr[1]);
      });
      img.setAttribute('id', `sigma-logo`);
      form.appendChild(img);
    } else {
      const span = document.createElement('span');
      span.classList.add('sigma-title');
      span.innerHTML = SigmaChat.#options.title;
      form.appendChild(span);
    }


    const p = document.createElement('p');
    p.classList.add('sigma-headline');
    p.innerHTML = SigmaChat.#options.headline;
    form.appendChild(p);

    if (SigmaChat.#options.hideField.includes('name') == false) {
      const divName = document.createElement('div');
      form.appendChild(divName);

      const labelName = document.createElement('label');
      labelName.classList.add('sigma-label');
      labelName.setAttribute('for', 'sigma-input-name');
      labelName.innerText = SigmaChat.#options.lang.name;
      divName.appendChild(labelName);

      const inputName = document.createElement('input');
      inputName.classList.add('sigma-input');
      inputName.setAttribute("type", "text");
      inputName.setAttribute("name", "firstName");
      inputName.setAttribute("required", "");
      inputName.setAttribute('placeholder', SigmaChat.#options.lang.namePH);
      inputName.setAttribute('id', 'sigma-input-name');
      divName.appendChild(inputName);
    }

    if (SigmaChat.#options.hideField.includes('email') == false) {
      const divEmail = document.createElement('div');
      form.appendChild(divEmail);

      const labelEmail = document.createElement('label');
      labelEmail.classList.add('sigma-label');
      labelEmail.setAttribute('for', 'sigma-input-email');
      labelEmail.innerText = SigmaChat.#options.lang.email;
      divEmail.appendChild(labelEmail);

      const inputEmail = document.createElement('input');
      inputEmail.classList.add('sigma-input');
      inputEmail.setAttribute("type", "email");
      inputEmail.setAttribute("name", "email");
      inputEmail.setAttribute("required", "");
      inputEmail.setAttribute("placeholder", SigmaChat.#options.lang.emailPH);
      inputEmail.setAttribute('id', 'sigma-input-email');
      divEmail.appendChild(inputEmail);
    }

    if (SigmaChat.#options.hideField.includes('phone') == false) {
      const divPhone = document.createElement('div');
      form.appendChild(divPhone);

      const labelPhone = document.createElement('label');
      labelPhone.classList.add('sigma-label');
      labelPhone.setAttribute('for', 'sigma-input-phone');
      labelPhone.innerHTML = SigmaChat.#options.lang.phone;
      divPhone.appendChild(labelPhone);

      const inputPhone = document.createElement('input');
      inputPhone.classList.add('sigma-input');
      inputPhone.setAttribute("type", "tel");
      inputPhone.setAttribute("name", "number1");
      inputPhone.setAttribute("required", "");
      inputPhone.setAttribute("placeholder", SigmaChat.#options.lang.phonePH);
      inputPhone.setAttribute('id', 'sigma-input-phone');
      divPhone.appendChild(inputPhone);

      IMask(inputPhone, SigmaChat.#options.phone.maskOptions)
      if (SigmaChat.#options.phone.pattern) {
        inputPhone.setAttribute("pattern", SigmaChat.#options.phone.pattern);
        inputPhone.setAttribute("title", SigmaChat.#options.phone.title);
      }
    }

    SigmaChat.#options.fields.forEach((field, index) => {

      const div = document.createElement('div');
      form.appendChild(div);

      const label = document.createElement('label');
      if (field.type === "checkbox") {
        label.classList.add('sigma-label-checkbox');
      } else {
        label.classList.add('sigma-label');
      }
      label.setAttribute('for', `sigma-input-field-${index}`);
      label.innerText = field.label || '';
      div.appendChild(label);
      if(Array.isArray(field.options)){
        const select = document.createElement('select');
        select.classList.add('sigma-input-select');
        const customClasses = (field.class || "").split(" ").filter(value => value !== '');
        if (customClasses.length > 0) {
          select.classList.add(...customClasses);
        }
        select.setAttribute('id', `sigma-input-field-${index}`);
        Object.entries(field).forEach(attr => {
          if (['class', 'label', 'id', 'style', 'maskOptions', 'options'].includes(attr[0])) {
            return;
          }
          select.setAttribute(attr[0], attr[1]);
        });
        for(let i = 0; i < field.options.length; i++) {
          const option = document.createElement('option');
          option.setAttribute("label", field.options[i].text);
          option.setAttribute("value", field.options[i].value);
          if(field.options[i].disabled){
            option.setAttribute("disabled", "true");
          }
          if(field.options[i].selected){
            option.setAttribute("selected", "true");
          }
          select.appendChild(option);
        }
        div.appendChild(select);
      } else {
        const input = document.createElement('input');
        if (field.type === "checkbox") {
          input.classList.add('sigma-input-checkbox');
          div.classList.add('sigma-checkbox-div');
        } else {
          input.classList.add('sigma-input');
        }
        const customClasses = (field.class || "").split(" ").filter(value => value !== '');
        if (customClasses.length > 0) {
          input.classList.add(...customClasses);
        }
        Object.entries(field).forEach(attr => {
          if (['class', 'label', 'id', 'style', 'maskOptions'].includes(attr[0])) {
            return;
          }
          input.setAttribute(attr[0], attr[1]);
          if (field.maskOptions) {
            IMask(input, field.maskOptions);
          }
        });
        input.setAttribute('id', `sigma-input-field-${index}`);
        div.appendChild(input);
      }

    });

    const divText = document.createElement('div');
    divText.classList.add('sigma-footer');
    divText.innerHTML = SigmaChat.#options.footer
    form.appendChild(divText)

    const divButton = document.createElement('div');
    form.appendChild(divButton);

    const button = document.createElement('button');
    button.classList.add('sigma-btn');
    button.setAttribute("type", "submit");
    button.innerText = SigmaChat.#options.lang.submit;
    divButton.appendChild(button);
  }

  static #startChat() {
    const myForm = document.getElementById('sigma-chat');
    if (!myForm) {
      return;
    }
    const formData = new FormData(myForm);
    this.#user = Object.fromEntries(formData);
    if (this.#user.number1 && this.#options.phone.code) {
      const number = document.querySelector('input[type="tel"]');
      const mask = IMask(number, this.#options.phone.maskOptions);
      this.#user.number1 = this.#options.phone.code + mask.unmaskedValue;
    }

    const root = document.querySelector('body div.sigma-container')
    if (!root) {
      return;
    }
    root.replaceChildren();

    const online = document.createElement('div');
    online.classList.add('sigmaHeaderChat');
    online.classList.add('row');
    online.innerHTML = `
          <div class='sigma-status'>
            <div class='sigma-img-icon'>
              <img style="border-radius: 50%" src='${SigmaChat.#options.icon.src || (process.env.MIX_PUSHER_HOST + '/dist/img/Favicon_Nuveto_SIGMA.png')}'>
            </div>
          </div>
        `;
    root.appendChild(online);

    var headerChat = document.querySelector('.sigmaHeaderChat');

    const secondColumn = document.createElement('div');
    secondColumn.classList.add('sigma-header-chat');
    headerChat.appendChild(secondColumn);


    const h2 = document.createElement('h2');
    h2.innerText = SigmaChat.#options.lang.assistance;
    secondColumn.appendChild(h2);

    const paragraphOnline = document.createElement('p');
    paragraphOnline.classList.add('sigma-online');
    paragraphOnline.innerHTML = `<p class='sigma-online'>Online</p>`;
    secondColumn.appendChild(paragraphOnline);


    const btnClose = document.createElement('a');
    btnClose.classList.add('sigma-btn-close');
    btnClose.classList.add('col-2')
    btnClose.setAttribute('href', '#');
    btnClose.setAttribute('onclick', 'SigmaChat.close()');
    btnClose.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style='color: white; margin-right: 0.5vw;'>
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>`
    headerChat.appendChild(btnClose);

    if(SigmaChat.#options.video) {
      const video = document.createElement('div');
      video.classList.add('sigma-video-robot');
      video.style.maxHeight = SigmaChat.#options.video.height || "115px"
      video.style.minHeight = SigmaChat.#options.video.height || "115px"
      video.innerHTML = `
        <video loop width="100%" autoplay="true">
          <source loop src="${SigmaChat.#options.video.src || ''}" type="${SigmaChat.#options.video.type || ''}">
          <object loop data="" width="100%" alt="" />
          <embed loop width="100%" src="${SigmaChat.#options.video.src || ''}">
        </video>
      `
      root.appendChild(video);
    }

    const form = document.createElement('form');
    form.classList.add('sigma-form');
    form.setAttribute('onsubmit', 'event.preventDefault();SigmaChat.sendMessage()');
    root.appendChild(form);

    const div = document.createElement('div');
    div.classList.add('sigma-messages');
    if(SigmaChat.#options.video && (SigmaChat.#options.video.src || "") !== "") {
      div.style.height = '332px'
    } else {
      div.style.height = '405px'
    }
    form.appendChild(div);

    const divSendMessage = document.createElement('div');
    divSendMessage.classList.add('sigma-component-send-message')
    form.appendChild(divSendMessage)

    const labelAttachment = document.createElement('label');
    labelAttachment.classList.add('sigma-label-attachment');
    labelAttachment.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
      </svg>
    `
    divSendMessage.appendChild(labelAttachment)

    const upload = document.createElement('input');
    upload.classList.add('sigma-input');
    upload.setAttribute("type", "file");
    upload.setAttribute("name", "file");
    upload.setAttribute("id", "sigma-file-msg");
    upload.addEventListener("change", function(e) {

      var el = document.querySelector("#sigma-file-msg").files[0].name;
      var input = document.querySelector("#sigma-input-msg");

      var iconAttachment = document.querySelector(".sigma-label-attachment");

      if (el != '') {
        input.style.marginLeft = '1.5rem';
        input.setAttribute("readOnly", "true");
        input.value = el;

        document.querySelector("#sigma-file-msg").style.display = "none";

        iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-excel" viewBox="0 0 16 16">
          <title>Cancelar Envio</title>
          <path d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z"/>
          <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
        </svg>`;

        iconAttachment.style.display = "block";

        var deleteAttachment = document.querySelector(".bi-file-excel");
        deleteAttachment.addEventListener("click", function(e) {

          var getInput = document.querySelector("#sigma-input-msg");
          getInput.removeAttribute("readOnly");
          getInput.style.marginLeft = '0.5rem'
          input.value = '';

          var teste = document.querySelector(".sigma-input")
          teste.value = ''

          iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
          </svg>`;


          document.querySelector("#sigma-file-msg").style.display = "block";
          iconAttachment.style.marginRight = "0";
          input.style.margiLeft = '.5rem';
        })

      }

    })
    divSendMessage.appendChild(upload);

    const inputMessage = document.createElement('input');
    inputMessage.classList.add('sigma-input');
    inputMessage.setAttribute("type", "text");
    inputMessage.setAttribute("name", "message");
    inputMessage.setAttribute("placeholder", SigmaChat.#options.lang.messagePH);
    inputMessage.setAttribute("autocomplete", 'off');
    inputMessage.setAttribute("id", "sigma-input-msg");
    divSendMessage.appendChild(inputMessage);

    const divInput = document.createElement('div');
    divInput.classList.add('sigma-send-message');
    divSendMessage.appendChild(divInput);

    var sendMessage = document.querySelector('.sigma-send-message')

    const divButton = document.createElement('div');
    sendMessage.appendChild(divButton);

    const button = document.createElement('button');
    button.classList.add('sigma-btn');
    button.setAttribute("type", "submit");
    button.innerText = SigmaChat.#options.lang.submit;
    button.addEventListener("click", function(event) {
      var el = document.querySelector("#sigma-input-msg");
      el.removeAttribute("readOnly");
      el.setAttribute("value", "");
      el.style.marginLeft = '.5rem';


      var iconAttachment = document.querySelector(".sigma-label-attachment");

      iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
      </svg>`;

      document.querySelector("#sigma-file-msg").style.display = "block";

    })
    divButton.appendChild(button);

    this.#clientId = uuidv4()

    Echo.connect();
    Echo.channel(`chat.${this.#clientId}`)
      .listen('NewMessage', (e) => {
        this.#renderMessage({ system: true, ...e.message })
      });

    if(SigmaChat.#options.autoMessage){
      const input = document.querySelector('#sigma-input-msg');
      input.value = SigmaChat.#options.autoMessage;
      this.sendMessage()
    }
  }


  static #renderMessage(message) {
    const direction = message.system ? 'incoming' : 'outcoming';
    var div = null;
    var chooseOptions = null
    if (message.buttons && Array.isArray(message.buttons)) {
      const automaticMessage = document.createElement('div');
      automaticMessage.classList.add('sigma-assistent-message');
      chooseOptions = `
          <div class='sigma-options-incoming sigma-show-div'>
              <div class='sigma-btn-options'>
              `
      for(var i = 0; i < message.buttons.length; i++){
        chooseOptions += `<button type="button" onclick="SigmaChat.sendInteractiveMessage('${SigmaChat.#encodeHTML(message.buttons[i].label).replaceAll('&#39;', '#39;').replaceAll('&quot;', 'quot;')}','${SigmaChat.#encodeHTML(message.buttons[i].value)}')" class='sigma-btn-message'>${SigmaChat.#encodeHTML(message.buttons[i].label)}</button>`
      }
      chooseOptions += `
              </div>
          </div>
          `;
    }

    if (message.attachment) {
      div = document.createElement('div');
      if (direction === 'incoming') {
        div.classList.add('sigma-assistent-message');
      }

      let type = message.type.split('/')[0];
      type = message.type.split(';')[0] == 'text/html' ? 'html' : type
      switch(type){
        case 'audio':
          div.innerHTML = `
          <div class="sigma-message-${direction}-audio">
            <div class="sigma-mesage-${direction}-audio-content">
              ${message.name && message.name !== 'null' ? `<p class="sigma-message-${direction}-name">` + message.name + '</p>' : ''}
              <audio class="sigma-message-${direction}-attachment-audio" controls src="${message.attachment}" alt="${message.filename}"></audio>
              <a class="sigma-message-${direction}-audio-download sigma-message-${direction}" href="${message.attachment}" target="_blank" downlaod="${message.filename}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                ${SigmaChat.#options.lang.download}
              </a>
              ${message.text && message.text !== 'null' ? `<p class="sigma-message-${direction}-text">` + message.text + '</p>' : ''}
            </div>
            <p class="sigma-message-${direction}-time-attachment"> ${moment().format(SigmaChat.#options.dateFormat)}</p>
          </div>`;
          break;
        case 'video':
          div.innerHTML = `
          <div class="sigma-message-${direction}-video">
            <div class="sigma-mesage-${direction}-video-content">
              ${message.name && message.name !== 'null' ? `<p class="sigma-message-${direction}-name">` + message.name + '</p>' : ''}
              <video class="sigma-message-${direction}-attachment-video" controls>
                <source src="${message.attachment}" type="${message.type}">
                ${SigmaChat.#options.lang.video_not_support}
              </video>
              <a class="sigma-message-${direction}-video-download sigma-message-${direction}" href="${message.attachment}" target="_blank" downlaod="${message.filename}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                ${SigmaChat.#options.lang.download}
              </a>
              ${message.text && message.text !== 'null' ? `<p class="sigma-message-${direction}-text">` + message.text + '</p>' : ''}
            </div>
            <p class="sigma-message-${direction}-time-attachment"> ${moment().format(SigmaChat.#options.dateFormat)}</p>
          </div>`;
          break;
        case 'html':
          div.innerHTML = `
          <div class="sigma-message-${direction}-html">
            <div class="sigma-mesage-${direction}-html-content">
              ${message.name && message.name !== 'null' ? `<p class="sigma-message-${direction}-name">` + message.name + '</p>' : ''}
              <iframe class="sigma-message-${direction}-attachment-html" src="${message.attachment}" allowfullscreen></iframe>
              <a class="sigma-message-${direction}-html-download sigma-message-${direction}" href="${message.attachment}" target="_blank" downlaod="${message.filename}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                ${SigmaChat.#options.lang.download}
              </a>
              ${message.text && message.text !== 'null' ? `<p class="sigma-message-${direction}-text">` + message.text + '</p>' : ''}
            </div>
            <p class="sigma-message-${direction}-time-attachment"> ${moment().format(SigmaChat.#options.dateFormat)}</p>
          </div>`;
          break;
        default:
          let image = message.attachment
          let ext = image.split(".").pop();
          switch (ext) {
            case 'pdf':
              image = `${process.env.MIX_PUSHER_HOST}/dist/img/imagem_pdf.png`
              break;
            case 'txt':
              image = `${process.env.MIX_PUSHER_HOST}/dist/img/image_txt.png`
              break;
            case 'docx':
              image = `${process.env.MIX_PUSHER_HOST}/dist/img/word.png`
              break;
            case 'xlsx':
              image = `${process.env.MIX_PUSHER_HOST}/dist/img/excel.png`
              break;
            case 'csv':
              image = `${process.env.MIX_PUSHER_HOST}/dist/img/excel.png`
              break;
            default:
              image
          }
          div.innerHTML = `
          <div class="sigma-message-${direction}-image">
            <div class="sigma-mesage-${direction}-image-content">
              ${message.name && message.name !== 'null' ? `<p class="sigma-message-${direction}-name">` + message.name + '</p>' : ''}
              <img class="sigma-message-${direction}-attachment-image" src="${image}" alt="${message.text}">
              <a class="sigma-message-${direction}-image-download" href="${message.attachment}" target="_blank" downlaod>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                ${SigmaChat.#options.lang.download}
              </a>
              ${message.text && message.text !== 'null' ? `<p class="sigma-message-${direction}-text">` + message.text + '</p>' : ''}
            </div>
            <p class="sigma-message-${direction}-time-attachment"> ${moment().format(SigmaChat.#options.dateFormat)}</p>
          </div>`;

      }

      if (direction === 'incoming') {
        div.innerHTML = div.innerHTML + `
            <div class='sigma-user_client'>
              <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
                <img style="border-radius: 50%" src='${SigmaChat.#options.icon.src || (process.env.MIX_PUSHER_HOST + '/dist/img/Favicon_Nuveto_SIGMA.png')}'>
              </div>
            </div>
          `;
      } else {
        if(SigmaChat.#options.userIcon){
          div.innerHTML = div.innerHTML + `
              <div class='sigma-user_client'>
                <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
                  <img style="border-radius: 50%" src='${SigmaChat.#options.userIcon.src || (process.env.MIX_PUSHER_HOST + '/dist/img/Favicon_Nuveto_SIGMA.png')}'>
                </div>
              </div>
            `;
        } else {
          div.innerHTML = div.innerHTML + `
              <div class='sigma-user_client'>
                <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
                  <p class='sigma-user'>${this.#user.firstName[0] || '?'}</p>
                </div>
              </div>
            `;
        }

      }
      const messages = document.querySelector('.sigma-messages');
      messages.appendChild(div);
      messages.scrollTop = messages.scrollHeight;

    } else {

      var regexp = /(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/gi;
      var matches_array = message.message.match(regexp);
      let mensagem = message.message

      if (regexp !== null) {
        mensagem = message.message.replace(matches_array, `<a target="_blank" href="${matches_array}">${matches_array}</a>`)
      }

      div = document.createElement('div');
      if (direction === 'incoming') {
        div.classList.add('sigma-assistent-message');
      }
      div.innerHTML = `
            <div class="sigma-message-${direction}">
             <div class="sigma-mesage-${direction}-content">
              <p class="sigma-message-${direction}-name"> ${message.name} </p>
              <p class="sigma-message-${direction}-text"> ${mensagem.replace(/\n/g, "<br />")}</p>
              ${chooseOptions && process.env.MIX_PUSHER_BUTTON_INSIDE ? chooseOptions : '' }
              </div>
              <div class='sigma-time'>
                <p class="sigma-message-${direction}-time"> ${moment().format(SigmaChat.#options.dateFormat)}
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16" style="display: none;">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16" style="display: none;">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16" style="display: none;">
                    <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                  </svg>
                </p>
              </div>
            </div>
            `;
      if (direction === 'incoming') {
        div.innerHTML = div.innerHTML + `
              <div class='sigma-user_client'>
                <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
                  <img style="border-radius: 50%" src='${SigmaChat.#options.icon.src || (process.env.MIX_PUSHER_HOST + '/dist/img/Favicon_Nuveto_SIGMA.png')}'>
                </div>
              </div>
            `;
      } else {
        if(SigmaChat.#options.userIcon){
          div.innerHTML = div.innerHTML + `
              <div class='sigma-user_client'>
                <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
                  <img style="border-radius: 50%" src='${SigmaChat.#options.userIcon.src || (process.env.MIX_PUSHER_HOST + '/dist/img/Favicon_Nuveto_SIGMA.png')}'>
                </div>
              </div>
            `;
        } else {
          div.innerHTML = div.innerHTML + `
          <div class='sigma-user_client'>
          <div class='sigma-message-${direction}-name sigma-message-${direction}-icon'>
          <p class='sigma-user'>${this.#user.firstName[0] || '?'}</p>
          </div>
          </div>
          `;
        }
      }

      const messages = document.querySelector('.sigma-messages');
      messages.appendChild(div);
      messages.scrollTop = messages.scrollHeight;

    }

    if (message.buttons && Array.isArray(message.buttons)) {
      const automaticMessage = document.createElement('div');
      automaticMessage.classList.add('sigma-assistent-message');

      if(!process.env.MIX_PUSHER_BUTTON_INSIDE) {
        automaticMessage.innerHTML = chooseOptions
        const automaticMessageContainer = document.querySelector('.sigma-messages');
        automaticMessageContainer.appendChild(automaticMessage);
      }
      const messages = document.querySelector('.sigma-messages');
      messages.scrollTop = messages.scrollHeight;
    }

  }

  static #decodeHTML(str) {
    var txt = document.createElement('textarea');
    txt.innerHTML = String(str).replaceAll('#39;', '&#39;').replaceAll('quot;', '&quot;');
    return txt.value;
  }

  static #encodeHTML(str) {
    var txt =  String(str).replaceAll(/[\u00A0-\u9999<>\&]/g, function(i) {
      return '&#'+i.charCodeAt(0)+';';
    }).replaceAll('\'', '&#39;').replaceAll('\"', '&quot;');
    return txt;
  }


  static sendMessage() {
    const formData = new FormData();
    const input = document.querySelector('#sigma-input-msg');
    const file = document.querySelector('#sigma-file-msg');

    formData.append('message', input.value);
    formData.append('clientId', this.#clientId);
    Object.entries(this.#user).forEach((data) => {
      formData.append(`user[${data[0]}]`, data[1]);
    });
    if (file.files.length > 0) {
      formData.append('attachment', file.files[0]);
      var reader = new FileReader();
      const uuid = `sigma-attachment-${uuidv4()}.${file.files[0].type.match(/[^\/]+\/(\w+)\.*/i)[1]}`;
      this.#renderMessage({ message: input.value, name: this.#user.firstName, attachment: uuid, type: 'unknown/unknown' });
      reader.onload = function (e) {
        const attachment = document.querySelector(`img[src="${uuid}"]`);
        const downalod = document.querySelector(`a[href="${uuid}"]`);
        attachment.src = e.target.result;
        downalod.href = e.target.result;
      };
      reader.readAsDataURL(file.files[0]);
    } else {
      this.#renderMessage({ message: input.value, name: this.#user.firstName });
    }

    const options = {
      method: 'POST',
      body: formData
    }
    input.value = ""
    file.value = ""
    fetch(process.env.MIX_PUSHER_HOST + '/chat/sigma/callback/' + SigmaChat.#options.id, options);
  }

  static sendInteractiveMessage(label, value){
    var optionSelected = document.querySelector('.sigma-show-div');
    if(!process.env.MIX_PUSHER_HOST){
      optionSelected.classList.add("sigma-hide");
      optionSelected.classList.remove("sigma-show-div");
    } else {
      var buttons = document.querySelectorAll('button:not(disabled).sigma-btn-message');
      buttons.forEach( button => {
        button.disabled = true;
        if(button.innerHTML == label){
          button.classList.add('sigma-btn-message-selected')
        }
      })
    }
    const formData = new FormData();

    formData.append('message', SigmaChat.#decodeHTML(label));
    formData.append('button[label]', SigmaChat.#decodeHTML(label));
    formData.append('button[value]', SigmaChat.#decodeHTML(value));
    formData.append('clientId', this.#clientId);
    Object.entries(this.#user).forEach((data) => {
      formData.append(`user[${data[0]}]`, data[1]);
    });

    this.#renderMessage({ message: SigmaChat.#decodeHTML(label), name: this.#user.firstName });

    const options = {
      method: 'POST',
      body: formData
    }
    fetch(process.env.MIX_PUSHER_HOST + '/chat/sigma/callback/' + SigmaChat.#options.id, options);
  }

  static showChat() {
    this.#drawChat();
    if(SigmaChat.#options.assistent == true) {
      const popup = document.querySelector('.sigma-popup');
      popup.remove();
    }
  }

  static initChat() {
    this.#startChat();
  }

  static close() {
    const chat = document.querySelector('.sigma-container')
    document.body.removeChild(chat);
    const event = new Event('SigmaChat-Close');
    document.dispatchEvent(event);
  }


  static closePopup() {
    const popup = document.querySelector('.sigma-popup');
    popup.remove();
  };

  // static clearForm() {
  //   var teste = document.querySelector('#sigma-input-msg');
  //   teste.removeAttribute('value');
  // }
}

